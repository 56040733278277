var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"submit-payment-modal",attrs:{"id":"submit-payment-modal","title":"บันทึกการชำระ","hide-footer":"","centered":""}},[_c('validation-observer',{ref:"paymentForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|min_value:0.1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"*จำนวนเงินที่ชำระ (บาท)","label-for":"amount"}},[_c('b-form-input',{attrs:{"id":"amount","state":errors[0] ? false : null,"placeholder":"0.00"},model:{value:(_vm.payAmount),callback:function ($$v) {_vm.payAmount=$$v},expression:"payAmount"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"*หลักฐานการชำระเงิน (ถ้ามี)","label-for":"file"}},[_c('b-form-file',{attrs:{"id":"file","accept":"image/*","browse-text":"อัพโหลด","placeholder":_vm.file?'':'ยังไม่ได้เลือกไฟล์',"state":errors[0] ? false : null},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('b-form-text',[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? errors[0] : ''))]),(_vm.file)?_c('b-link',{staticClass:"text-danger",on:{"click":function($event){_vm.file = ''}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")]):_vm._e()],1)],1)]}}])}),_c('b-form-group',{attrs:{"label":"ยอดค้างชำระ (บาท)","label-for":"ยอดค้างชำระ","label-cols":"6","label-class":"text-secondary"}},[_c('b-form-input',{staticClass:"text-right text-white",staticStyle:{"font-weight":"600"},attrs:{"id":"ยอดค้างชำระ","value":_vm._f("currency")(_vm.paymentAmount),"plaintext":""}})],1),_c('b-form-group',{attrs:{"label":"ยอดชำระ (บาท)","label-for":"ยอดชำระ (บาท)","label-cols":"6","label-class":"text-secondary"}},[_c('b-form-input',{staticClass:"text-right text-white",staticStyle:{"font-weight":"600"},attrs:{"id":"ยอดชำระ (บาท)","value":_vm._f("currency")(_vm.payAmount),"plaintext":""}})],1),_c('b-form-group',{attrs:{"label":"ยอดค้างคงเหลือ (บาท)","label-for":"ยอดค้างคงเหลือ (บาท)","label-cols":"6","label-class":"text-secondary"}},[_c('b-form-input',{staticClass:"text-right",class:{
            'text-danger': _vm.payAmount < _vm.paymentAmount,
            'text-success': _vm.payAmount >= _vm.paymentAmount,
          },staticStyle:{"font-weight":"600"},attrs:{"id":"ยอดค้างคงเหลือ (บาท)","value":_vm._f("currency")(_vm.remainAmount),"plaintext":""}})],1),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":_vm.hideModal}},[_vm._v(" ยกเลิก ")]),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }