<template>
  <b-modal
    id="submit-payment-modal"
    ref="submit-payment-modal"
    title="บันทึกการชำระ"
    hide-footer
    centered
  >
    <validation-observer ref="paymentForm">
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="amount"
          rules="required|min_value:0.1"
        >
          <b-form-group
            label="*จำนวนเงินที่ชำระ (บาท)"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="payAmount"
              :state="errors[0] ? false : null"
              placeholder="0.00"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="file"
          rules="required"
        > 
          <b-form-group
            label="*หลักฐานการชำระเงิน (ถ้ามี)"
            label-for="file"
          >
            <b-form-file
              id="file"
              v-model="file"
              accept="image/*"
              browse-text="อัพโหลด"
              :placeholder="file?'':'ยังไม่ได้เลือกไฟล์'"
              :state="errors[0] ? false : null"
            />
            <b-form-text>
              <span class="text-danger">{{ errors[0] ? errors[0] : '' }}</span>
              <b-link
                v-if="file"
                class="text-danger"
                @click="file = ''"
              >
                {{ $t('buttons.remove') }}
              </b-link>
            </b-form-text>
          </b-form-group>
        </validation-provider>

        <b-form-group
          label="ยอดค้างชำระ (บาท)"
          label-for="ยอดค้างชำระ"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="ยอดค้างชำระ"
            :value="paymentAmount | currency"
            class="text-right text-white"
            style="font-weight: 600;"
            plaintext
          />
        </b-form-group>
        <b-form-group
          label="ยอดชำระ (บาท)"
          label-for="ยอดชำระ (บาท)"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="ยอดชำระ (บาท)"
            :value="payAmount | currency"
            class="text-right text-white"
            style="font-weight: 600;"
            plaintext
          />
        </b-form-group>
        
        <b-form-group
          label="ยอดค้างคงเหลือ (บาท)"
          label-for="ยอดค้างคงเหลือ (บาท)"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="ยอดค้างคงเหลือ (บาท)"
            :value="remainAmount | currency"
            class="text-right"
            :class="{
              'text-danger': payAmount < paymentAmount,
              'text-success': payAmount >= paymentAmount,
            }"
            style="font-weight: 600;"
            plaintext
          />
        </b-form-group>
        <br>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ยกเลิก
          </b-button>
          <b-overlay :show="isLoading">
            <b-button
              variant="primary"
              type="submit"
            >
              บันทึก
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { required, min_value } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SubmitPaymentModal',
  props: {
    invoice: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // validations
      required,
      min_value,

      file: null,

      payAmount: 0,
      
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.payment.isMakingPayment,
      isSuccess: (state) => state.payment.isMakingPaymentSuccess,
    }),
    ...mapGetters(['requestDetail']),
    paymentAmount() {
      return this.invoice?.outstanding || 0
    },
    remainAmount() {
      return this.paymentAmount - this.payAmount;
    }
  },
  watch: {
    isSuccess(val) {
      if (val) {
        this.hideModal()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['makePayment']),
    setData() {
      this.payAmount = 0;
      this.file = null;
    },
    onSubmit() {
      this.$refs.paymentForm.validate().then(async (success) => {
        if (success) {
          if(this.invoice.id){
            const form = {
              image: this.file,
              invoiceId: this.invoice.id,
              amount: `${this.payAmount}`
            }
            this.makePayment(form)
          }
        }
      })
    },
    hideModal() {
      this.$bvModal.hide('submit-payment-modal')
      this.$emit('cancel')
    },
    onClickConfirm() {
      this.$emit('confirm')
      this.$bvModal.hide('submit-payment-modal')
    },
  },
  
}
</script>


<style lang="scss">

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'เลือกไฟล์';
}
</style>
